


































import { Vue, Component, Prop } from "vue-property-decorator";
import Company from "@/entity/Company";

@Component
export default class ContactCompanies extends Vue {
  @Prop() company!: Company;

  get isCompaniesValidList(): boolean {
    return this.company != null;
  }
}
